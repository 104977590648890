import axios from 'axios'
import helpers from "@/utils/helpers";
const baseURL = helpers.genBaseURL()

const apiClient = axios.create({
  baseURL: baseURL + '/api/v1',
  withCredentials: true,
  headers: {
    Authorization: 'Bearer ts_tok',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
export default {
  getDevices() {
    return apiClient.get('/devices')
  },
  getDevicesFiltered(filter) {
    return apiClient.post('/devices/filtered', filter)
  },
  addDevice(device) {
    return apiClient.post('/devices', device)
  },
  updateDevice(device) {
    return apiClient.patch('/devices', device)
  },
  getDevice(id) {
    return apiClient.get(`/devices/${id}`)
  },
  deleteDevice(id) {
    return apiClient.delete(`/devices/${id}`)
  },
  getCategories() {
    return apiClient.get('/categories')
  },
  getEmployees() {
    return apiClient.get('/employees')
  },
  addEmployee(employee) {
    return apiClient.post('/employees', employee)
  },
  getSystems() {
    return apiClient.get('/systems')
  },
  getStatuses() {
    return apiClient.get('/statuses')
  },
  getDepartments() {
    return apiClient.get('/departments')
  },
  getWarrantyTypes() {
    return apiClient.get('/warrantyTypes')
  },
  getUsers() {
    return apiClient.get('/users')
  },
  getUsersFiltered(filter) {
    return apiClient.post('/users/filtered', filter)
  },
  getUser(id) {
    return apiClient.get(`/users/${id}`)
  }
}
