import AuthService from '@/services/AuthService'
import DeviceService from '@/services/DeviceService'

const state = {
  userSession: {
    user: {
      id: 0
    }
  },
  loggedIn: false,
  categories: [],
  departments: [],
  employees: [],
  users: [],
  statuses: [],
  systems: [],
  appTitle: ''
}

const getters = {
  currentUserId(state) {
    return state.userSession.user !== undefined ? state.userSession.user.id : 0
  },
  username(state) {
    return state.userSession.user !== undefined ? state.userSession.user.username : ''
  },
  isAdmin(state) {
    return state.userSession.user !== undefined ? state.userSession.user.type === 3 : false
  }
}

const actions = {
  async getProfile({commit, state}) {
    try {
      let user = await dao.profile()
      commit('login', user)
      localStorage.setItem(dao.TOKEN_NAME, user.token)
      return ''
    } catch (exp) {
      commit('logout', {})
      return exp.toString()
    }
  },
  async getCategories({commit, state}) {
    await DeviceService.getCategories().then(async response => {
      commit('setCategories', response.data.data)
    })
  },
  async getEmployees({commit, state}) {
    await DeviceService.getEmployees().then(async response => {
      commit('setEmployees', response.data.data)
    })
  },
  async getUsers({commit, state}) {
    await DeviceService.getUsers().then(async response => {
      commit('setUsers', response.data.data)
    })
  },
  async getStatuses({commit, state}) {
    await DeviceService.getStatuses().then(async response => {
      commit('setStatuses', response.data.data)
    })
  },
  async getSystems({commit, state}) {
    await DeviceService.getSystems().then(async response => {
      commit('setSystems', response.data.data)
    })
  },
  async getDepartments({commit, state}) {
    await DeviceService.getDepartments().then(async response => {
      commit('setDepartments', response.data.data)
    })
  },
  async getWarrantyTypes({commit, state}) {
    await DeviceService.getWarrantyTypes().then(async response => {
      commit('setWarrantyTypes', response.data.data)
    })
  },
  async login({commit, state}, credentials) {
    try {
      await AuthService.login(credentials).then(async response => {
        commit('login', response.data)
      })

      return ''
    } catch (exp) {
      commit('logout', {})

      return exp.toString()
    }
  },
  async logout({commit, state}) {
    try {
      AuthService.logout().then(response => {
        console.log(response)
      })
      localStorage.setItem(AuthService.TOKEN_NAME, null)
      commit('logout', {})
      return ''
    } catch (exp) {
      return exp.toString()
    } finally {

    }
  }
}

const mutations = {
  login(state, userSession) {
    state.userSession = userSession
    state.loggedIn = true
  },
  setCategories(state, ctg) {
    state.categories = ctg
  },
  setEmployees(state, emp) {
    state.employees = emp
  },
  setUsers(state, usr) {
    state.users = usr
  },
  setStatuses(state, stat) {
    state.statuses = stat
  },
  setSystems(state, sys) {
    state.systems = sys
  },
  setDepartments(state, stat) {
    state.departments = stat
  },
  setWarrantyTypes(state, sys) {
    state.warrantyTypes = sys
  },
  logout(state) {
    state.userSession = {}
    state.loggedIn = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
