export default {
  headers: [
    {text: 'SPRZĘT', value: 'name'},
    {text: 'SERIAL', value: 'serialNo', align: ' d-none'},
    {text: 'PRZYJMUJĄCY', value: 'recipient'},
    {text: 'WYDAJĄCY', value: 'issuer'},
    {text: 'KATEGORIA', value: 'category'},
    {text: 'DATA WYDANIA', value: 'issueDate'},
    {text: 'STATUS', value: 'status'},
    {text: 'AKCJE', value: 'actions'}
  ],
  userHeaders: [
    {text: 'UŻYTKOWNIK', value: 'username'},
    {text: 'IMIE', value: 'name', align: ' d-none'},
    {text: 'NAZWISKO', value: 'surname', align: ' d-none'},
    {text: 'EMAIL', value: 'email'},
    {text: 'ROLA', value: 'role'},
    {text: 'AKTYWNY', value: 'active'},
    {text: 'AKCJE', value: 'actions'}
  ],
  roles: [
    {text: 'Administrator', value: 1, color: 'primary'},
    {text: 'Edytor', value: 2, color: 'success'},
    {text: 'CC', value: 3, color: 'error'},
  ],
  contractorHeaders: [
    {text: 'Nazwa', value: 'sellerName'},
    {text: 'NIP', value: 'NIP'},
    {text: 'AKCJE', value: 'actions'}
  ],
  invoicesHeaders: [
    {text: 'Numer', value: 'fullNumber'},
    {text: 'Numer źródłowy', value: 'sourceDocument'},
    {text: 'Sprzedawca', value: 'sellerName'},
    {text: 'Data przyjęcia', value: 'receiptDate'},
    {text: 'Data dodania', value: 'sellDate'},
    {text: 'Termin płatności', value: 'paymentDate'},
    {text: 'Data utworzenia', value: 'creationDate'},
    {text: 'Netto', value: 'totalNet'},
    {text: 'VAT', value: 'totalVAT'},
    {text: 'Brutto', value: 'totalGross'},
    {text: 'Status', value: 'statusId'},
    {text: 'AKCJE', value: 'actions'}
  ],
  mapHeaders : [
    {text: 'Zadanie', value: 'rmNo'},
    {text: 'Streszczenie', value: 'summary'},
    {text: 'Miejsce', value: 'errorLocation'},
    {text: 'Ryzyko', value: 'risks'},
    {text: 'Powód', value: 'reasons'},
  ],
  getRole(id, value) {
    if (id != null && value != null) {
      return (this.roles.filter(i => i.value === id)[0])[value]
    } else {
      return null
    }
  },
  genAvatar(name, surname) {
    if (name != null && surname != null) {
      return name[0] + surname[0]
    } else {
      return 'NN'
    }
  },
  genBaseURL() {
    const PROD = false
    if (PROD) {
      return ''
    } else {
      return 'http://localhost:8000'
    }

  }
}
