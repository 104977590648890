import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue')
  },
  {
    path: '/contractors',
    name: 'contractors',
    component: () => import('@/views/contractors/ContractorList.vue')
  },
  {
    path: '/contractors/edit/:id',
    name: 'contractors-edit',
    component: () => import('@/views/contractors/ContractorEdit.vue')
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/invoices/InvoiceList.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/map/MapDashboard.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/mapsankey',
    name: 'mapsankey',
    component: () => import('@/views/map/MapSankey.vue')
  },
  {
    path: '/maprisk',
    name: 'maprisk',
    component: () => import('@/views/map/MapRisk.vue')
  },
  {
    path: '/add_invoice',
    name: 'add_invoice',
    component: () => import('@/views/invoices/InvoiceAdd.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue')
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/Jobs.vue')
  },
  {
    path: '/dictionaries',
    name: 'dictionaries',
    component: () => import('@/views/Dictionaries.vue')
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue')
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/cc',
    name: 'cc',
    component: () => import('@/views/CCServiceUpload.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/cc/:id',
    name: 'ccview',
    component: () => import('@/views/CCService.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/forgottenpass',
    name: 'forgotten-password',
    component: () => import('@/views/pages/ForgottenPassword.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('@/components/Documentation.vue')
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
