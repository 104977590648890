import axios from 'axios'
import helpers from "@/utils/helpers";
const baseURL = helpers.genBaseURL()

const apiClient = axios.create({
  baseURL: baseURL + '/auth',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
export default {
  login(loginData) {
    return apiClient.post('/login', loginData)
  },
  logout() {
    return apiClient.get('/logout')
  }
}
